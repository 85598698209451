.Posts-container {
  max-width: 90vw;
  min-width: 90vw;
  background-color: #EEE;
}


.Posts-container-inner {
  display: flex;
  flex-direction: column;
  margin: 2vh;  
}

.Posts-error {
	color: red;
}


