.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.App-header {
  background-color: #282c34;
  /*min-height: 10vh;*/
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: left;
  /*justify-content: center;*/
  font-size: calc(1.20vmin);
  color: white;
  border-bottom: 1px solid #444;
}
