.Posts-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 90vw;
}

.Posts-input div {
	margin: 10px;
}

.Posts-submit {
	color: white;
	background-color: #282c34;
	padding: 7px;
	border-radius: 4px;
	cursor: pointer;
}
