.Post-container {
	dispaly: flex;
	flex-direction: column;
	margin: 1vh;
}

.Post-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 100%;	
	background-color: #282c34;
	color: white;
	padding: 5px;
}

.Post-author {
	color: gray;
}

.Post-body {
	padding: 5px;
	background-color: #DDD;
}

.Post-votes span {
	margin: 6px;
}

.Post-votes-votes {
	color: gray;
}

.Post-votes-action {
	cursor: pointer;
}
